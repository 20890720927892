//Import >React Library
import React from "react";

//Imports icons
import { ReactComponent as ZoomIn } from "../../assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOut } from "../../assets/icons/zoom-out.svg";
import { ReactComponent as ZoomReset } from "../../assets/icons/zoom-reset.svg";
import { ReactComponent as TurnClockwise } from "../../assets/icons/turn-clock.svg";
import { ReactComponent as TurnAntiClockwise } from "../../assets/icons/turn-anti.svg";
import { ReactComponent as Print } from "../../assets/icons/print.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/circle-left.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/circle-right-arrow.svg";
import { ReactComponent as Total } from "../../assets/productIcons/Total.svg";
import { ReactComponent as Loyal } from "../../assets/productIcons/Loyal.svg";
import { ReactComponent as Infolex } from "../../assets/productIcons/Infolex.svg";
import { ReactComponent as First } from "../../assets/icons/circle-first.svg";
import { ReactComponent as Last } from "../../assets/icons/circle-end.svg";
import { ReactComponent as Lexis } from "../../assets/productIcons/lexis.svg";
import { ReactComponent as RO } from "../../assets/images/logo-ro.svg";

//Set width of the icon
var iconWidth = "30px";
var iconWidth2 = "50px";

//Creates an icon list
export const IconList = [
  {
    ZoomIn: <ZoomIn stroke="#ffffff" width={iconWidth} />,
    ZoomOut: <ZoomOut stroke="#ffffff" width={iconWidth} />,
    ZoomReset: <ZoomReset stroke="#ffffff" width={iconWidth} />,
    TurnClockwise: (
      <TurnClockwise stroke="#ffffff" fill="#ffffff" width={iconWidth} />
    ),
    TurnAntiClockwise: (
      <TurnAntiClockwise stroke="#ffffff" fill="#ffffff" width={iconWidth} />
    ),
    LeftArrow: <LeftArrow stroke="#ffffff" width={iconWidth} />,
    RightArrow: <RightArrow stroke="#ffffff" width={iconWidth} />,
    Print: (
      <Print
        stroke="#ffffff"
        fill="#ffffff"
        width={iconWidth}
        height={iconWidth}
      />
    ),
    Download: (
      <Download
        stroke="#ffffff"
        fill="#ffffff"
        width={iconWidth}
        height={iconWidth}
      />
    ),
    Total: <Total width={iconWidth2} height={iconWidth2} fill="#ffffff" />,
    Loyal: <Loyal width={iconWidth2} height={iconWidth2} fill="#ffffff" />,
    Infolex: <Infolex width={iconWidth2} height={iconWidth2} fill="#ffffff" />,
    First: <First stroke="#ffffff" width={iconWidth} />,
    Last: <Last stroke="#ffffff" width={iconWidth} />,
    Lexis: <Lexis width={iconWidth2} height={iconWidth2} />,
    RO: <RO width={iconWidth2} height={iconWidth2} />,
  },
];
