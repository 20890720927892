//Import React library
import React from "react";

//Import style sheet
import "./css/App.css";

//Import components
import Toolbar from "./Components/Toolbar/Toolbar";
import { PacmanLoader } from "./Components/PacmanLoader";

//Import material design library
// import { MDBRow, MDBInput, MDBIcon } from "mdbreact";
// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "./Context/context";
function App() {
  return (
    <React.Fragment>
      <ProductConsumer>
        {/*Used for state management*/}
        {(context) => {
          return context.show === true ? (
            <Toolbar />
          ) : (
            <PacmanLoader marginTop="40vh" />
          );
        }}
        {/*Show toolbar or banner*/}
      </ProductConsumer>
    </React.Fragment>
  );
}

export default App;
