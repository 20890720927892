import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import "@fortawesome/fontawesome-free/css/all.min.css"; //Import fontawesome library
import "bootstrap-css-only/css/bootstrap.min.css"; //Import bootstrap library
import "mdbreact/dist/css/mdb.css"; //Import material design bootstrap react library
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ProductProvider } from './Context/context'; //Import context api
import { BrowserRouter as Router } from 'react-router-dom'; // Import elements for routing
ReactDOM.render(
    <ProductProvider>
        <Router> {/* Render route */}
            <App /> {/* Render App.js */}
        </Router>
    </ProductProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
