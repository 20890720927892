//Import React Library
import React, { Component } from "react";

//Import Material Design library
import { MDBTooltip } from "mdbreact";

//Define style
const style = { display: "inline-block", maxWidth: "50vh" };

export default class GenericIcon extends Component {
  componentDidMount() {}
  render() {
    return (
      <div style={style} className={"noSpace text-center " + this.props.estilo}>
        <MDBTooltip placement="bottom" domElement className="tooltipToHide">
          {/*renders tooltip container*/}
          <button
            className="PaginationIcon GenericIcon py-1"
            onClick={this.props.clic}
            type="button"
            disabled={this.props.disabled}
          >
            {" "}
            {this.props.icon}{" "}
          </button>
          {/*Renders icon*/}
          <div className="tooltipToHide text-center">{this.props.tooltip}</div>
          {/*Renders tootip content*/}
        </MDBTooltip>
      </div>
    );
  }
}
