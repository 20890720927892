//Import React Library
import React, { Component } from "react";

//Import Keyboard handler library
import KeyboardEventHandler from "react-keyboard-event-handler";

//Import material design library
import { MDBRow, MDBTypography, MDBCol, MDBContainer } from "mdbreact";

// Import ProductConsumer from Context Api (used for state management)
import { ProductConsumer } from "../../Context/context";

//Import pagination library
import Pagination from "react-paginating";

//Import library for zoom and pan for images
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

//Import library for printing image
import ReactToPrint from "react-to-print";

//import icon list
import { IconList } from "./IconList";

//Import icon reusable component
import GenericIcon from "./GenericIcon";

//Import measure tool
import Measure from "react-measure";

//Set the amazon url
var s3Url = "https://esilecstorage.s3.amazonaws.com/biblioteca_silec/REGOFICI/";
var newS3Url =
  "https://esilecstorage.s3.amazonaws.com/biblioteca_silec/REGOFJPG/";

//Declare variables and constants
const limit = 1;
// const rowsPerPage = limit;
const pageCount = 1;
var urlToReturn = ";";
// var iconWidth = "30px";

export default class Toolbar extends Component {
  //Declare states
  state = {
    disablePan: false, //Disables pan on touch devices
  };

  //Enable/Disable pan
  setPan = (state) => {
    // console.log("disabledPan: ", state);
    this.setState(() => {
      return {
        disablePan: state,
      };
    });
  };

  render() {
    return (
      <ProductConsumer>
        {(context) => {
          //const {  total } = context; //Stores current page and total page

          //var totalItems = total; //Stores total pages
          var icon = ""; //Stores product icon
          var title = ""; //Stores product title
          //console.log(context.currentPage)
          //console.log(context.RoOriginal)
          if (context.product === "Total") {
            icon = IconList[0].Total;
            urlToReturn = "http://total.finder.lexis.com.ec/Login/login.aspx";
            title = "TOTAL";
          } //Total case
          else if (context.product === "Loyal") {
            icon = IconList[0].Loyal;
            urlToReturn = "http://loyal.finder.lexis.com.ec/Login/login.aspx";
            title = "LOYAL";
          } //Loyal case
          else if (context.product === "Zone") {
            icon = IconList[0].RO;
            urlToReturn = "http://zone.lexis.com.ec/";
            title = "Z-ONE";
          } //Loyal case
          else if (context.product === "Infolex") {
            icon = IconList[0].Infolex;
            urlToReturn = "#";
            title = "INFOLEX";
          } //Infolex case
          else {
            icon = IconList[0].RO;
            urlToReturn = "#";
            title = "LEXIS";
          } //Default
          // console.log(context);

          return (
            <TransformWrapper
              defaultScale={1} //Initial scale
              defaultPositionX={0} //Initial position
              defaultPositionY={0}
              wheel={{ wheelEnabled: false }} //Disable wheel zoom (wheel is used for scroll)
              pan={{ disabled: this.state.disablePan }} //Enble/disable pan according to device
              zoomIn={{ step: 6 }} //zoom in step
              zoomOut={{ step: 6 }} //zoom out step
              doubleClick={{ step: 8 }} //double click step
              className="w-100 Wrapper"
              options={{
                limitToWrapper: false,
                limitToBounds: false,
              }} //disable bound limits
            >
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <MDBContainer className="noSpace ScreenBg" fluid>
                  {/*Main container*/}
                  <KeyboardEventHandler
                    handleKeys={["left", "right"]}
                    onKeyEvent={(key, e) => context.handleKey(`${key}`)}
                  />
                  {/*Keyboard handler*/}

                  <Measure
                    bounds
                    onResize={(contentRect) => {
                      context.setToolbarHeight(contentRect.bounds.height);
                      //console.log(contentRect.bounds);
                    }}
                  >
                    {({ measureRef }) => (
                      <div
                        ref={measureRef}
                        className={
                          "row noSpace  w-100 fixed-top  " +
                          context.product +
                          "Bg"
                        }
                        style={{ backgroundColor: context.themeColor }}
                        fluid
                      >
                        {" "}
                        {/*toolbar container*/}
                        <MDBCol size="7" lg="3" className="mx-auto">
                          {/*Brand section*/}
                          <a href={urlToReturn}>
                            <MDBRow className="noSpace justify-content-center py-2">
                              {icon}
                              {/*Brand logo*/}
                              <MDBTypography
                                tag="h2"
                                variant="h2-responsive"
                                className="my-auto pl-3 title text-center"
                              >
                                {title}
                              </MDBTypography>
                              {/*brand name*/}
                            </MDBRow>
                          </a>
                        </MDBCol>
                        <MDBCol size="12" lg="6" className="order-last noSpace">
                          {/*Main toolbar: pagination, zoom, rotate */}
                          <Pagination //Pagination defnition
                            className="noSpace h-100 my-auto justify-content-center py-2 mx-auto"
                            total={context.total} //total pages
                            limit={limit}
                            pageCount={pageCount}
                          >
                            {({
                              pages,
                              hasNextPage,
                              hasPreviousPage,
                              currentPage,
                              totalPages,
                              getPageItemProps,
                            }) => (
                              <MDBRow className="noSpace justify-content-center h-100">
                                <MDBCol size="12" sm="6" className="noSpace">
                                  {/*Pagination container*/}
                                  <MDBRow className="noSpace justify-content-center h-100">
                                    <MDBCol
                                      className="noSpace text-center my-auto"
                                      size="2"
                                    >
                                      {/*First page container*/}
                                      <GenericIcon
                                        estilo="PaginationBtn my-auto"
                                        {...getPageItemProps({
                                          pageValue: 1,
                                        })}
                                        disabled={
                                          context.currentPage === 1
                                            ? true
                                            : false
                                        }
                                        icon={IconList[0].First}
                                        tooltip="Primera Página"
                                        clic={() => context.handlePageChange(1)}
                                      />
                                    </MDBCol>
                                    <MDBCol
                                      className="noSpace text-center my-auto"
                                      size="2"
                                    >
                                      {/*Previous page container*/}
                                      <GenericIcon
                                        estilo="PaginationIcon my-auto"
                                        {...getPageItemProps({
                                          pageValue:
                                            parseInt(context.currentPage) - 1,
                                        })}
                                        clic={() =>
                                          context.handlePageChange(
                                            parseInt(context.currentPage) - 1
                                          )
                                        }
                                        disabled={
                                          context.currentPage === 1
                                            ? true
                                            : false
                                        }
                                        icon={IconList[0].LeftArrow}
                                        tooltip="Anterior"
                                      />
                                    </MDBCol>
                                    <MDBCol
                                      className="noSpace my-auto text-center my-auto"
                                      size="4"
                                    >
                                      {/*Page input container*/}
                                      {pages.map((page) => {
                                        let activePage = null;
                                        if (currentPage === page) {
                                          activePage = {
                                            backgroundColor: "var(--mainZone)",
                                          };
                                        }
                                        return (
                                          <MDBRow className="noSpace">
                                            <MDBCol
                                              size="6"
                                              className="noSpace text-right"
                                            >
                                              <input
                                                className="PaginationInput text-center my-auto mx-auto font-weight-bolder"
                                                min={1}
                                                type="number"
                                                max={context.total}
                                                placeholder={
                                                  context.currentPage
                                                }
                                                onChange={(e) =>
                                                  context.handlePageInputChange(
                                                    e
                                                  )
                                                }
                                                value={context.currentPage}
                                              ></input>
                                              {/*Page input*/}
                                            </MDBCol>
                                            <MDBCol
                                              size="6"
                                              className="noSpace my-auto"
                                            >
                                              {/*total page indicator*/}
                                              <p className="noSpace text-white text-center my-auto mx-auto font-weight-bolder">
                                                {"  /  " + context.total}
                                              </p>
                                            </MDBCol>
                                          </MDBRow>
                                        );
                                      })}
                                    </MDBCol>
                                    <MDBCol
                                      className="noSpace text-center my-auto"
                                      size="2"
                                    >
                                      {/*Next page container*/}
                                      <GenericIcon
                                        estilo="PaginationIcon py-0 my-auto"
                                        {...getPageItemProps({
                                          pageValue:
                                            parseInt(context.currentPage) + 1,
                                        })}
                                        clic={() =>
                                          context.handlePageChange(
                                            parseInt(context.currentPage) + 1
                                          )
                                        }
                                        disabled={
                                          context.currentPage === context.total
                                            ? true
                                            : false
                                        }
                                        icon={IconList[0].RightArrow}
                                        tooltip="Siguiente"
                                      />
                                    </MDBCol>
                                    <MDBCol
                                      className="noSpace text-center my-auto"
                                      size="2"
                                    >
                                      {/*Last page container*/}
                                      <GenericIcon
                                        estilo="PaginationIcon my-auto"
                                        {...getPageItemProps({
                                          pageValue: totalPages,
                                        })}
                                        clic={() =>
                                          context.handlePageChange(totalPages)
                                        }
                                        disabled={
                                          context.currentPage === context.total
                                            ? true
                                            : false
                                        }
                                        icon={IconList[0].Last}
                                        tooltip="Última Página"
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>

                                <MDBCol
                                  size="12"
                                  sm="6"
                                  className="noSpace justify-content-center"
                                >
                                  {" "}
                                  {/*Zoom and rotate container*/}
                                  <MDBRow className="noSpace justify-content-center h-100">
                                    <MDBCol className="noSpace text-center my-auto">
                                      {/*Zoom in container*/}
                                      <GenericIcon
                                        icon={IconList[0].ZoomIn}
                                        clic={zoomIn}
                                        tooltip="Acercar"
                                        estilo="m-auto"
                                        disabled={false}
                                      />
                                    </MDBCol>
                                    <MDBCol className="noSpace text-center my-auto">
                                      {/*Zoom out container*/}
                                      <GenericIcon
                                        icon={IconList[0].ZoomOut}
                                        clic={zoomOut}
                                        tooltip="Alejar"
                                        estilo="my-auto"
                                        disabled={false}
                                      />
                                    </MDBCol>
                                    <MDBCol className="noSpace text-center my-auto">
                                      {/*Zoom reset container*/}
                                      <GenericIcon
                                        icon={IconList[0].ZoomReset}
                                        clic={resetTransform}
                                        tooltip="Restablecer"
                                        estilo="my-auto"
                                        disabled={false}
                                      />
                                    </MDBCol>
                                    <MDBCol className="noSpace text-center my-auto">
                                      {/*Rotate clockwise container*/}
                                      <GenericIcon
                                        icon={IconList[0].TurnClockwise}
                                        clic={() => context.rotate(90)}
                                        tooltip="Giro a la derecha"
                                        estilo="my-auto"
                                        disabled={false}
                                      />
                                    </MDBCol>
                                    <MDBCol className="noSpace text-center my-auto">
                                      {/*Rotate anti clockwise container*/}
                                      <GenericIcon
                                        icon={IconList[0].TurnAntiClockwise}
                                        clic={() => context.rotate(-90)}
                                        tooltip="Giro a la izquierda"
                                        estilo="my-auto"
                                        disabled={false}
                                      />
                                    </MDBCol>
                                  </MDBRow>
                                </MDBCol>
                              </MDBRow>
                            )}
                          </Pagination>
                        </MDBCol>
                        <MDBCol
                          size="5"
                          lg="3"
                          className="my-auto justify-content-center mx-auto  order-lg-last"
                        >
                          {" "}
                          {/*Print and download container*/}
                          <MDBRow className="noSpace justify-content-center">
                            <ReactToPrint
                              trigger={() => (
                                // <a href="#">
                                <GenericIcon
                                  icon={IconList[0].Print}
                                  tooltip="Imprimir"
                                  estilo=" mr-2"
                                />
                                // </a>
                              )}
                              content={() => this.componentRef}
                            />
                            {/*Print component*/}
                            {context.RoOriginal === "SI" ? (
                              <a
                                href={
                                  "https://esilecstorage.s3.amazonaws.com/biblioteca_silec/REGOFORIGINAL/" +
                                  context.year +
                                  "/" +
                                  context.ROHash +
                                  ".pdf"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="my-auto text-center"
                              >
                                {/*Download component*/}
                                <GenericIcon
                                  icon={IconList[0].Download}
                                  tooltip="Descargar RO en pdf"
                                />
                              </a>
                            ) : (
                              <a
                                href={
                                  "https://esilecstorage.s3.amazonaws.com/biblioteca_silec/REGOFPDF/" +
                                  context.year +
                                  "/" +
                                  context.ROHash +
                                  ".pdf"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className="my-auto text-center"
                              >
                                {/*Download component*/}
                                <GenericIcon
                                  icon={IconList[0].Download}
                                  tooltip="Descargar RO en pdf"
                                />
                              </a>
                            )}
                          </MDBRow>
                        </MDBCol>
                      </div>
                    )}
                  </Measure>
                  <MDBRow
                    className={
                      "noSpace ScreenBg justify-content-center w-100 " +
                      context.imgClass
                    }
                    style={{ marginTop: context.toolbarHeight }}
                  >
                    {/*Ro image container*/}
                    <MDBCol size="12" className="noSpace">
                      <TransformComponent className="Wrapper w-100">
                        <button
                          className={"w-100 h-100 GenericIcon btnGif"}
                          onScroll={() => this.setPan(true)}
                          onTouchEnd={() => this.setPan(false)}
                          onKeyDown={(e) => context.handleKey(e.keyCode)}
                        >
                          <img
                            src={
                              parseInt(context.year) < 2020
                                ? s3Url +
                                  context.year +
                                  "/" +
                                  context.imgName +
                                  ".GIF"
                                : newS3Url +
                                  context.year +
                                  "/" +
                                  context.imgName +
                                  ".jpg"
                            }
                            alt="Cargando..."
                            className={"w-100 h-100 ImgGif"}
                            ref={(el) => (this.componentRef = el)}
                          ></img>
                        </button>
                      </TransformComponent>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              )}
            </TransformWrapper>
          );
        }}
      </ProductConsumer>
    );
  }
}
