//Import React Library
import React, { Component } from "react";

// Create Context Api (used for state management)
const ProductContext = React.createContext();

//Import library for parsing the parameters in the URL
const queryString = require("query-string");
var jwtDecode = require("jwt-decode");
//declare  variables
var giro = 0; //Stores the angle of the image.
var imgClass = ""; //Stores the classname of the image
var colSize = 12; //Stores the column size of the image container
var desiredPage = 0;
var token = "";
var productName = "";

class ProductProvider extends Component {
  //State definition for all the app
  state = {
    zoom: 1, //Stores the zoom value
    angle: 0, // Stores the rotation angle in degrees
    currentPage: 1, //Stores the current page of the pdf
    total: 1, //Stores total pages number
    books: [], //Stores the json arrived from the api
    imgName: "", //Stores the name of the current image
    year: 1899, //Stores the year of the current pdf
    imgClass: "imgNormal", //Stores the classname of the image
    show: false, //True: Show visualizer when parameters from the url are valid. False: Show banner
    themeColor: "main(--varLexis)", // Stores the theme color of the atoolbar based on incoming product
    product: "Lexis", //Stores the product retrieved from the url parameter
    height: 0, //Stores the toolbar height
    colSize: 12, //Stores the column size of the image container
    ROname: "", // Stoes the name of the RO
    ROHash: "", // Stores the id of the RO,
    RoOriginal: "NO",
    isRoEnabled: false,
  };

  // componentDidMount() {
  //   this.handleQuery(); //Parse the url parameters
  // }

  async componentDidMount() {
    //console.log(sessionStorage.getItem('lhl'))
    //this.handleQuery();
    //console.log(sessionStorage.getItem('productName'))
    var urlInfo = queryString.parseUrl(window.location.href);

    //Parse id parameter from url
    var codRO = urlInfo.query.id;
    //console.log(codRO)

    //Parse type parameter from url
    // var type = urlInfo.query.type
    //console.log(type)

    //Parse productName parameter from url
    // var productName = urlInfo.query.productName
    //console.log(productName)

    //Parse page parameter from url
    if (urlInfo.query.page !== undefined) {
      desiredPage = urlInfo.query.page;
    } else {
      desiredPage = 1;
    }

    //Display visualizer only if parameters are valid
    if (codRO !== undefined) {
      this.loadbooks(codRO); //Call api using id
    }

    if (
      (sessionStorage.getItem("lvro") === null) |
      (sessionStorage.getItem("lvro") === undefined)
    ) {
      token = urlInfo.query.token;
      //console.log(token)
    } else {
      token = sessionStorage.getItem("lvro");
      //console.log(token)
      //this.setState({ isRoEnabled: true });
    }

    if (
      (sessionStorage.getItem("productName") === null) |
      (sessionStorage.getItem("productName") === undefined)
    ) {
      productName = urlInfo.query.productName;
      sessionStorage.setItem("productName", productName);
      //console.log(productName)
    } else {
      productName = sessionStorage.getItem("productName");
      //console.log(productName)
    }

    var datos = { token: token };
    if ((productName !== null) & (productName !== "undefined")) {
      //   console.log(productName);
      if (productName === "LOYAL") {
        this.setState({
          urlRedirect:
            "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "TOTAL") {
        this.setState({
          urlRedirect:
            "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "LEGACY") {
        this.setState({
          urlRedirect:
            "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
        });
      } else if (productName === "ZONE") {
        this.setState({ urlRedirect: "https://zone.lexis.com.ec/" });
      }
    }

    if ((token !== null) & (token !== undefined) & (token !== "")) {
      // console.log("entra token dif null")
      await fetch("http://lam.finder.lexis.com.ec/api/v1/auth/validateToken", {
        // await fetch("http://192.168.85.128:49461/api/v1/auth/validateToken", {
        method: "post",
        body: JSON.stringify(datos),
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (!response.ok) {
            return response
              .json()
              .catch(() => {
                // Couldn't parse the JSON
                // console.log(response.status);
                this.setState({ isRoEnabled: false });

                sessionStorage.setItem("lvro", null);
              })
              .then(({ message }) => {
                // Got valid JSON with error response, use it
                // console.log(message || response.status);
                this.setState({ isRoEnabled: false });

                sessionStorage.setItem("lvro", null);
                /*this.setState(() => {
                                    return {
                                        colorAlert: "danger",
                                        textAlert: message,
                                        visibility: "visible"
                                    };
                                }); //Set alert fields according to fetch response (state=error)*/
              });
          }

          // Successful response, parse the JSON and return the data
          return response.json();
        })
        .then((json) => {
          //   console.log("parsed json", json);
          if (json !== undefined) {
            if (json.length === 0) {
              var decoded = jwtDecode(token);
              //   console.log(decoded);
              var producto = decoded.productName;
              sessionStorage.setItem("productName", producto);
              if (producto === "LOYAL") {
                this.setState({
                  urlRedirect:
                    "https://loyal.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "TOTAL") {
                this.setState({
                  urlRedirect:
                    "https://total.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "LEGACY") {
                this.setState({
                  urlRedirect:
                    "https://legacy.finder.lexis.com.ec/WebTools/LexisFinder/Default.aspx",
                });
              } else if (producto === "ZONE") {
                this.setState({ urlRedirect: "https://zone.lexis.com.ec/" });
              } else {
                this.setState({
                  urlRedirect: "https://finder.lexis.com.ec/Login/login.aspx",
                });
              }

              this.setState({ isRoEnabled: true });

              sessionStorage.setItem("lvro", token);
              /*this.setState(() => {
                                return {
                                    colorAlert: "success",
                                    textAlert: "Datos guardados exitosamente",
                                    visibility: "visible"
                                };
                            }); //Set alert fields according to fetch response (state=ok) */
            } else {
              var strError = "";
              //   console.log(json[0].message);
              json.forEach((element) => {
                strError = strError + ", " + element.message;
              });
              //   console.log(strError);
              this.setState({ isRoEnabled: false });
              sessionStorage.setItem("productName", null);
            }
          } else {
            this.setState({ isRoEnabled: false });
            sessionStorage.setItem("productName", null);
          }
        });
    }

    var color = "var(--mainLexis)";
    //Set styling according to the product
    if (productName !== undefined) {
      if (
        (productName === "TOTAL") |
        (productName === "LOYAL") |
        (productName === "INFOLEX") |
        (productName === "LEXISNEWS")
      ) {
        color =
          "var(--main" +
          productName.charAt(0).toUpperCase() +
          productName.slice(1).toLowerCase() +
          ")";
      }

      //console.log(color)
      this.setState(() => {
        return {
          themeColor: color, //Set theme color
          product:
            productName.charAt(0).toUpperCase() +
            productName.slice(1).toLowerCase(), //Set product name
        };
      });
    }
  }

  //Parse the url parameters
  handleQuery = () => {
    //Retrieves url information
    var urlInfo = queryString.parseUrl(window.location.href);
    //console.log(window.location)

    //Parse id parameter from url
    var codRO = urlInfo.query.id;
    //console.log(codRO)

    //Parse type parameter from url
    // var type = urlInfo.query.type;
    //console.log(type)

    //Parse productName parameter from url
    var productName = urlInfo.query.productName;
    //console.log(productName)

    //Parse page parameter from url
    if (urlInfo.query.page !== undefined) {
      desiredPage = urlInfo.query.page;
    } else {
      desiredPage = 1;
    }

    //Display visualizer only if parameters are valid
    if (codRO !== undefined) {
      this.loadbooks(codRO); //Call api using id
    }

    var color = "var(--mainLexis)";
    //Set styling according to the product
    if (productName !== undefined) {
      if (
        (productName === "TOTAL") |
        (productName === "LOYAL") |
        (productName === "INFOLEX") |
        (productName === "LEXISNEWS")
      ) {
        color =
          "var(--main" +
          productName.charAt(0).toUpperCase() +
          productName.slice(1).toLowerCase() +
          ")";
      }

      //console.log(color)
      this.setState(() => {
        return {
          themeColor: color, //Set theme color
          product:
            productName.charAt(0).toUpperCase() +
            productName.slice(1).toLowerCase(), //Set product name
        };
      });
    }
  };

  //Call api for acquiring the RO data
  loadbooks = async (codRO) => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var datos = { codigoRegistroOficialHash: codRO }; //Set the id required

    //fetchfrom api
    await fetch(
      // "https://serviceslvro.finder.lexis.com.ec/api/v1/lvro/findimagero",
      "https://servicessecure.lexis.com.ec/api/v1/lvro/findimagero",
      {
        method: "post",
        body: JSON.stringify(datos),
        headers: myHeaders,
      }
    ).then((response) => {
      if (!response.ok) {
        // console.log(response);
        return response
          .json()
          .catch(() => {
            // Couldn't parse the JSON
            // console.log(response.status);
          })
          .then((json) => {
            // Got valid JSON with error response, use it
            // console.log(json.Message);
            alert(
              "Servicio en Mantenimiento: cod " +
                response.status +
                ". " +
                json.Message
            );
          });
      } else {
        return response.json().then((json) => {
          //console.log('parsed json', json.listaPaginasRO)
          //   console.log("parsed json", json);
          if (json !== undefined) {
            this.setBooks(json); //Stores json data in states
          }
        });
      }

      // Successful response, parse the JSON and return the data
      //return response.json();
    });
  };

  //Handles key pressed event
  handleKey = (key) => {
    // console.log(key);
    //Left key moves to the previous page
    if (key === "left" && this.state.currentPage > 1) {
      this.handlePageChange(this.state.currentPage - 1);
    }
    //Right key moves to the next page
    else if (key === "right" && this.state.currentPage < this.state.total) {
      this.handlePageChange(this.state.currentPage + 1);
    } else if (key === 37 && this.state.currentPage > 1) {
      this.handlePageChange(this.state.currentPage - 1);
    } else if (key === 39 && this.state.currentPage < this.state.total) {
      this.handlePageChange(this.state.currentPage + 1);
    }
  };

  //Stores json data in states
  setBooks = (json) => {
    var books = json.listaPaginasRO; //Array with pages info
    var year = json.codigoRegistroOficial.substr(
      json.codigoRegistroOficial.indexOf("_") + 1,
      4
    ); //Year of the RO
    /*console.log(json.CodigoRegistroOficial.indexOf("_"))
        console.log(year)*/
    // console.log(json);
    var ROname = json.codigoRegistroOficial; //Name of the RO
    var ROHash = json.codigoRegistroOficialHash; //Id of the RO
    var RoOriginal = json.roOriginal;
    let tempBooks = [];
    books.forEach((item) => {
      const singleItem = { ...item };
      tempBooks = [...tempBooks, singleItem];
    });

    // console.log("first", typeof Number(desiredPage));

    //Verify if page number is valid
    if (
      desiredPage <= 0 ||
      desiredPage > tempBooks.length ||
      desiredPage === undefined ||
      isNaN(desiredPage)
    ) {
      desiredPage = 1;
    }
    this.setState(() => {
      return {
        currentPage: desiredPage,
        books: tempBooks,
        total: tempBooks.length,
        imgName: tempBooks[desiredPage - 1].nombreArchivo,
        year: year,
        ROname: ROname,
        ROHash: ROHash,
        RoOriginal: RoOriginal,
        // currentPage: desiredPage,
        show: true, //Set the visualizer visibility state to true
      };
    });
  };

  //Set toolbar height
  setToolbarHeight = (height) => {
    this.setState(() => {
      return {
        toolbarHeight: height,
      };
    });
  };

  //Moves to the page defined on the text input
  handlePageInputChange = (e) => {
    // console.log(e.target.value);
    if (e.target.value <= this.state.total && e.target.value >= 1) {
      //   console.log("si entra");
      this.handlePageChange(parseInt(e.target.value));
    }
  };

  //Moves to the desired page
  handlePageChange = (page, e) => {
    /*console.log(page)
        console.log(this.state.total)*/
    if (page <= this.state.total && page >= 1) {
      this.setState({
        currentPage: page,
        imgName: this.state.books[page - 1].nombreArchivo,
      });
      /*console.log(page)
            console.log(this.state.books[page - 1].NombreArchivo)*/
    }
  };

  //set the zoom (in/out)
  setZoom = (increment) => {
    this.setState((prevState) => ({
      zoom: prevState.zoom + increment,
    }));
    // console.log(this.state.zoom);
  };

  //rotates the image
  rotate = (increment) => {
    //Restrict the angle between 0 and 260 degrees
    if (increment + this.state.angle === 360) {
      giro = 0;
    } else if (increment + this.state.angle === -90) {
      giro = 270;
    } else {
      giro = giro + increment;
    }
    //Set the orientation of the image
    if (giro === 180) {
      imgClass = "imgUpside";
      colSize = 10;
    } else if (giro === 90) {
      imgClass = "imgRotatedClockwise";
      colSize = 12;
    } else if (giro === 0) {
      imgClass = "imgNormal";
      colSize = 10;
    } else if (giro === 270) {
      imgClass = "imgRotatedAntiClockwise";
      colSize = 12;
    }
    this.setState({
      imgClass: imgClass,
      angle: giro,
      colSize: colSize,
    });
    // console.log(giro);
  };

  render() {
    return (
      <ProductContext.Provider
        value={{
          ...this.state,
          setZoom: this.setZoom,
          rotate: this.rotate,
          handlePageChange: this.handlePageChange,
          setBooks: this.setBooks,
          handleKey: this.handleKey,
          handlePageInputChange: this.handlePageInputChange,
          setToolbarHeight: this.setToolbarHeight,
        }} // Enable access to states and functions from ProductProvider
      >
        {this.props.children}
      </ProductContext.Provider>
    );
  }
}

//create context objet for consumer
const ProductConsumer = ProductContext.Consumer;

export { ProductProvider, ProductConsumer };
