import React from "react";

import Pacman from "react-spinners/PacmanLoader";
import { css } from "@emotion/react";

export const PacmanLoader = ({ marginTop, label = "Cargando" }) => {
    return (
        <React.Fragment>
            <Pacman
                css={css`
                        display: block;
                        margin: auto auto;
                        margin-top: ${marginTop} !important;
                        margin-bottom: 20px !important;
                    `}
                size={15}
                color="#143542"
                loading={true}
            />
            <p className="text-center">{label}</p>
        </React.Fragment>
    );
};
